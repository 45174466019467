<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Link exercise to a metric</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <LoadingMessage v-if="isLoading"></LoadingMessage>

          <div v-else>
            <div v-if="metricItems.length" class="list-group list-group-flush">
              <!-- GROUPS -->
              <div
                v-for="(g, gIndex) in metricGroups"
                :key="`key-group-${gIndex}`"
                class="list-group-item"
              >
                <div @click="toggleGroup(g.metricGroupId)" role="button">
                  <fa-icon
                    v-if="groupIsActive(g.metricGroupId)"
                    :icon="['fas', 'folder-open']"
                    class="me-1"
                  ></fa-icon>
                  <fa-icon v-else :icon="['fas', 'folder']" class="me-1"></fa-icon>
                  {{ g.name }}
                  <span
                    v-if="!metricItemsOnGroup(g.metricGroupId).length"
                    class="is-busy small"
                  >(empty)</span>
                </div>
                <div
                  v-if="
                    metricItemsOnGroup(g.metricGroupId).length &&
                    groupIsActive(g.metricGroupId)
                  "
                  class="list-group list-group-flush ms-4"
                >
                  <div
                    v-for="(m, mIndex) in metricItemsOnGroup(g.metricGroupId)"
                    :key="`key-group-${gIndex}-${mIndex}`"
                    class="list-group-item"
                  >
                    <div class="form-check">
                      <input
                        v-model="selectedMetricId"
                        :id="m.metricId"
                        :value="m.metricId"
                        class="form-check-input"
                        type="radio"
                        name="metric"
                      >
                      <label class="form-check-label" :for="m.metricId">
                        {{ m.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- METRICS -->
              <div
                v-for="(m, mIndex) in metricItemsOnRoot"
                :key="`key-${mIndex}`"
                class="list-group-item"
              >
                <div class="form-check">
                  <input
                    v-model="selectedMetricId"
                    :id="m.metricId"
                    :value="m.metricId"
                    class="form-check-input"
                    type="radio"
                    name="metric"
                  >
                  <label class="form-check-label" :for="m.metricId">
                    {{ m.name }}
                  </label>
                </div>
              </div>
            </div>
            <div v-else>No metrics found</div>
          </div>
        </div>
        <div class="modal-footer">
          <IconButton
            @click="fetchMetrics"
            :icon="'sync-alt'"
            :text="'Refresh metrics'"
            :class="{ 'is-invisible': isLoading }"
            class="btn btn-primary ms-2"
          ></IconButton>
          <SubmitButton
            @click="linkMetric"
            :text="'Link'"
            :disabled="selectedMetricId === ''"
            class="btn btn-primary ms-2"
          ></SubmitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MetricLinkModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('metric', ['metricItems', 'metricGroups']),
    ...mapGetters('metric', ['metricItemsOnRoot', 'metricItemsOnGroup', 'metricItemById']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async fetchMetrics() {
      this.selectedMetricId = '';
      this.openedGroups = [];
      this.isLoading = true;
      try {
        await this.$store.dispatch('metric/fetchItems');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    linkMetric() {
      const metric = this.metricItemById(this.selectedMetricId);
      if (metric) {
        this.$emit('link', metric);
        this.close();
      } else {
        this.$store.dispatch('addSystemError', new Error('Failed to assign metric.'), { root: true });
      }
    },
    toggleGroup(groupId) {
      const index = this.openedGroups.findIndex((id) => id === groupId);
      if (index > -1) {
        this.openedGroups.splice(index, 1);
      } else {
        this.openedGroups.push(groupId);
      }
    },
    groupIsActive(groupId) {
      const index = this.openedGroups.findIndex((id) => id === groupId);
      return index > -1;
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      selectedMetricId: '',
      openedGroups: [],
    };
  },
  mounted() {
    this.fetchMetrics();
  },
};
</script>
